// jQuery Whaaaaaat?
import $ from 'jquery';
window.$ = window.jQuery = $;

// uncomment to restore lightning fast vite, as is prevents flash of unstyled content
// import '../css/style.scss'

import './components/navigation.js';
import './components/search-widget.js';
import './components/tooltips.js';
import './components/sticky.js';
import './components/drawer.js';
import './components/modal.js';

// defunkt. replaced with view 'products-opt
// if (document.getElementById('isotope-instance')) {
//   import("./components/isotope.js");
// }

if (document.getElementById('products-opt')) {
  import("./components/products-opt.js");
}


if (document.getElementById('distributors-opt')) {
  import("./components/dist-opt.js");
  // import("./components/isotope-dist.js");
}


if (document.getElementById('state-regs')) {
  import("./components/stateRegs.js");
}

if (document.getElementById('slider')) {
  import("./components/tinyslider.js");
}
if (document.getElementById('tab')) {
  import("./components/tabs.js");
}
if (document.getElementById('scrolling-content')) {
  import("./components/hscroll.js");
}

if (document.getElementById('compare-page')) {
  import("./components/compare-table.js");
}



if (document.getElementById('product-info')) {
  import("./components/product-info.js");
}

if (document.getElementById('rewards')) {
  import("./components/app.js");
}

// accordion

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    var panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  });
}

	// getting us state info from IP
	if (!sessionStorage.location) {
		$.getJSON('https://ipinfo.io', function(location){
			sessionStorage.location = JSON.stringify(location);
		})
	}

  if (!sessionStorage.feed) {
    $.getJSON('https://pndata.andersonsinc.com/misc/StateRegistrations', function(feed){
			sessionStorage.feed = JSON.stringify(feed.StateRegistrations);
		})
  }



  // ========== Gorgias Chat Events ===========
  // GorgiasChat represents the gorgias chat box.
  if ( $('#js-chat-contact').length ) {

      var chatButton = document.getElementById('js-chat-contact');
      chatButton.addEventListener('click', function(event){
        // prevent default link behavior
        event.preventDefault();

        console.log('chat button clicked')

        // open the Pardot chat bubble

        // wait for the chat bubble to load
        setTimeout(function(){
          // click the chat bubble
          document.getElementById('embeddedMessagingConversationButton').click();
        }, 500);

      });


  }
  // ========== End Gorgias Chat Events ===========


  /// if the DOM is loaded...
  document.addEventListener('DOMContentLoaded', function() {
    // trigger a scroll event
    window.dispatchEvent(new Event('scroll'));

  } );
