import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css';


document.addEventListener( 'DOMContentLoaded', function () {

   tippy('.tippy', {
      allowHTML: true,
      arrow: true,
      animation: 'fade',
    })
  
});
  