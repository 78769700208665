document.getElementById('open-modal').addEventListener('click', function(e) {
  e.preventDefault();
  document.getElementById('compare-modal').classList.add('open');
  document.getElementById('compare-modal-overlay').classList.add('active');
});

document.getElementById('close-button').addEventListener('click', function(e) {
  e.preventDefault();
  document.getElementById('compare-modal').classList.remove('open');
  document.getElementById('compare-modal-overlay').classList.remove('active');
});

document.getElementById('fake').addEventListener('click', function(e) {
  e.preventDefault();
  document.getElementById('get-started-modal').classList.remove('open');
  document.getElementById('get-started-modal-overlay').classList.remove('active');
});

document.getElementById('get-started-close-button').addEventListener('click', function(e) {
  e.preventDefault();
  document.getElementById('get-started-modal').classList.remove('open');
  document.getElementById('get-started-modal-overlay').classList.remove('active');
});