const drawer = document.getElementById('compare-drawer');

// close the drawer when clicking outside of it
document.addEventListener('click', (event) => {
  if (!drawer.contains(event.target) && !event.target.classList.contains('compare')) {
    drawer.classList.remove('open');
  }
});

// open the drawer when clicking the button
document.getElementById('drawer-button').addEventListener('click', (event) => {
  event.stopPropagation();
  drawer.classList.toggle('open');
});

// handlers and method for removing all compares
['remove-all', 'remove-all-modal'].forEach(id => {
  document.getElementById(id).addEventListener('click', (event) => {
    event.preventDefault();
    removeAll(event);
  });
});

const removeAll = (event) => {

  const compareModal = document.getElementById('compare-modal');
  const compareModalOverlay = document.getElementById('compare-modal-overlay');

  compareModal.classList.remove('open');
  compareModalOverlay.classList.remove('active');

  sessionStorage.compareList = JSON.stringify([]);
  compareList = [];

  const els = document.getElementsByClassName('compare');
  Array.from(els).forEach(el => {
    el.classList.remove('added-to-compare');
    el.innerHTML = "Compare";
  });

  const compareCounts = document.getElementsByClassName('drawer-count');
  Array.from(compareCounts).forEach(count => {
    count.innerHTML = 0;
  });

  const div = document.getElementById('product-items');

  const html = `
    <div class="product-item"><a href="/products">Add a Product</a></div>
    <div class="product-item"><a href="/products">Add a Product</a></div>
    <div class="product-item"><a href="/products">Add a Product</a></div>
  `;

  div.innerHTML = html;

  const actions = document.getElementById('modal-actions');
  actions.classList.add('hidden');


}

var compareList

// compare list is not stored. do something!
if (!sessionStorage.compareList) {
  compareList = JSON.stringify([])
  sessionStorage.compareList = compareList;
} else {
  compareList = JSON.parse(sessionStorage.compareList);
}

var compareCounts = document.getElementsByClassName('drawer-count');

if (compareCounts.length > 0) {

  for (var i = 0; i < compareCounts.length; i++) {
    compareCounts[i].innerHTML = compareList.length;
  }

}

var els = document.getElementsByClassName('compare default');

for (var i = 0; i < els.length; i++) {

  // check if this is already in the compare list and update the button
  if ( compareList.indexOf( els[i].getAttribute('data-product-id') ) > -1 ) {
    els[i].classList.add('added-to-compare');
    els[i].innerHTML = "Remove";
  }
  
  els[i].addEventListener('click', function(event) {

    event.preventDefault();

    var productId = this.getAttribute('data-product-id');

    // save id to session (blanked out on first load )
    // var compareList = sessionStorage.getItem('compareList');
    // compareList = JSON.parse(compareList);

    if ( compareList.length > 2 && !this.classList.contains('added-to-compare') ) {
      alert('You can only compare up to 3 products at a time.');
      return;
    }

    // is product ID already in compare list?
    var index = compareList.indexOf(productId);
    if (index == -1) {
      // no. add it.
      compareList.push(productId);
      this.innerHTML = "Remove";
    } else {
      // yes. remove it.
      compareList.splice(index, 1);
      this.innerHTML = "Compare";
    }
    
    this.classList.toggle("added-to-compare");

    sessionStorage.setItem('compareList', JSON.stringify(compareList));

    // update compare count

    // var compareCounts = document.getElementsByClassName('drawer-count');

    if (compareCounts.length > 0) {

      for (var i = 0; i < compareCounts.length; i++) {
        compareCounts[i].innerHTML = compareList.length;
      }

    }

    // if not comparing already, open the drawer and put a timer on 

    if ( this.classList.contains('added-to-compare') ){
      var drawer = document.getElementById('compare-drawer');
      drawer.classList.add('open');

      setTimeout(function() {
        drawer.classList.remove('open');
      }, 5000);
    }
    

    // update the modal

    var data = {
      compareList: compareList
    };

    var tokenName = window.csrfTokenName;
    data[ tokenName ] = window.csrfTokenValue;

    $.ajax( {
      type: "post",
      data: data,
      url: '/actions/_mad-utilities/mad/do-something',
      success: function ( response ) {
        updateCompare(response);
      },
      error: function(e){
        console.log("ERROR");
        console.log(e);
      }
    });

  });
  
}

var els = document.getElementsByClassName('compare tabular');

for (var i = 0; i < els.length; i++) {
  els[i].addEventListener('click', function(event) {
    event.preventDefault();
    
    var productId = this.getAttribute('data-product-id');

    // is ID already in compare list?
    var index = compareList.indexOf(productId);
    if (index == -1) {
      compareList.push(productId);
    } else {
      compareList.splice(index, 1);
    }

    //console.log(compareList);
  
    sessionStorage.setItem('compareList', JSON.stringify(compareList));
  
    // update the compare count
    if (compareCounts.length > 0) {
      for (var i = 0; i < compareCounts.length; i++) {
        compareCounts[i].innerHTML = compareList.length;
      }
    }

    // hide the elements. note if page gets reload the POST will have to be updated to hide the elements on page load
    var tdEls = document.getElementsByClassName('td-' + productId);
    for (var i = 0; i < tdEls.length; i++) {
      tdEls[i].classList.toggle('hidden');
    }

  
  });
}

function updateCompare(response) {
    var r = JSON.parse(response);
    var div = document.getElementById('product-items');
    div.innerHTML = r.html;
    var input = document.getElementById('compare-list');
    input.value = r.compareList;
    //console.log(r.compareList);

    var actions = document.getElementById('modal-actions');
    if ( r.compareList.length <= 1 ) {
      actions.classList.add('hidden');
    } else {
      actions.classList.remove('hidden');
    }

    // get all of the modal compare buttons with the class name of 'compare'
    var modalCompareButtons = document.getElementsByClassName('compare-modal');

    // loop through all of the modal compare buttons
    for (var i = 0; i < modalCompareButtons.length; i++) {
      // add a click event listener to each of the modal compare buttons
      modalCompareButtons[i].addEventListener('click', function(e) {
        // prevent the default action of the link
        e.preventDefault();
        // get the parent element
        var parent = this.parentElement.parentElement.parentElement;

        // replace the parent element
        var div = document.createElement('div');
        div.classList.add('product-item');
        div.innerHTML = '<a href="/products">Add a Product</a>';

        parent.replaceWith(div);

        // get the product id
        var productId = this.getAttribute('data-product-id');
        // is ID already in compare list?
        var index = compareList.indexOf(productId);
        if (index == -1) {
          compareList.push(productId);
        } else {
          compareList.splice(index, 1);
        }

        // update the compare list in session storage
        sessionStorage.setItem('compareList', JSON.stringify(compareList));

        // update the compare count
        if (compareCounts.length > 0) {
          for (var i = 0; i < compareCounts.length; i++) {
            compareCounts[i].innerHTML = compareList.length;
          }
        }

        // hide the actions buttons if the count is only 1
        var actions = document.getElementById('modal-actions');
        if ( compareList.length <= 1 ) {
          actions.classList.add('hidden');
          // close the modal
          document.getElementById('compare-modal').classList.remove('open');
          document.getElementById('compare-modal-overlay').classList.remove('active');
        } else {
          actions.classList.remove('hidden');
        }

        // update the hidden input element
        var input = document.getElementById('compare-list');
        input.value = compareList;


      });
    }

    var actions = document.getElementById('modal-actions');
      if ( r.compareList.length <= 1 ) {
        actions.classList.add('hidden');
      } else {
        actions.classList.remove('hidden');
      }

}


document.addEventListener( 'DOMContentLoaded', function () {

  var data = {
    compareList: compareList
  };
  
  var tokenName = window.csrfTokenName;
  data[ tokenName ] = window.csrfTokenValue;
  
  $.ajax( {
    type: "post",
    data: data,
    url: '/actions/_mad-utilities/mad/do-something',
    success: function ( response ) {
      // console.log( response );
      updateCompare(response);
    },
    error: function(e){
      console.log("ERROR");
      console.log(e);
    }
  });

 
});

export {updateCompare}

